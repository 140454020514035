<!-- 付款结果-->
<template>
  <div class="payresult">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='支付结果'></logotitle>
      </el-col>
      <el-col :span="12">
      </el-col>
    </el-row>
    <resultok  :infos='payresult' class='resultok'></resultok>
    <!-- <resultok v-if='this.$route.query.state==1' :infos='JSON.parse(this.$route.query.data)' class='resultok'></resultok> -->
    <!-- <resultno v-if='this.$route.query.state==0' :infos='JSON.parse(this.$route.query.data)' class='resultno'></resultno> -->
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import resultok from '@/components/resultok/resultok.vue';//支付成功
import resultno from '@/components/resultno/resultno.vue';//支付失败
export default {
  name: 'payresult',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'支付结果-微微定',
      payresult:{},//支付结果
    }
  },
  components: {
    logotitle,
    resultok,
    resultno
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/ExistOrderStatus?orderName='+this._spf(this.$route.query.ordername),this.keys())
        .then(res => {
            if(res.data.Success){
              if(res.data.Data.state==200){
                // console.log(res.data.Data)
                this.payresult = res.data.Data
              }
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.payresult {
  width: 100%;background: #fff;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;padding-bottom:20px;
    .buystep {
      float:right;margin-top:10px;
    }
  }
  .resultok {
    width: 1200px;margin:0 auto;padding-bottom:150px;padding-top:20px;
  }
  .resultno {
    width: 1200px;margin:0 auto;padding-bottom:150px;padding-top:20px;
  }
}
</style>
