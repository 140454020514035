<!-- 付款结果-->
<template>
  <div class="resultno">
    <div class="title">
      <div class="lt">
        <img src="../../assets/img/payment/payok.png">
      </div>
      <div class="rt">
        <p>抱歉！支付失败！<span>!</span></p>
      </div>
    </div>
    <div class="reason">
      <div class="p">
        支付失败原因：<span>{{reasons}}</span>
      </div>
      <div class="p1">
        您可能需要：<span>选择其他支付方式</span>
      </div>
      <div class="p2">
        <button>联系客服>></button>问题已解决？返回重新支付或查看订单
      </div>
    </div>
    <div class="menus">
      <button>返回支付</button>
      <button>查看订单</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'resultno',
  data(){
    return {
      reasons:'余额不足'
    }
  },
  components: {
  },
  props: {
    result: String
  },
  methods:{
  }
}
</script>
<style  lang="scss" scoped>
.resultno {
  .title {
    display: flex;justify-content: center;height: 230px;align-items: center;
    .lt {
      img {
        display: block;width: 220px;
      }
    }
    .rt {
      margin-left:30px;
      p {
        font-size: 20px;font-weight: bold;
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        span {
          display: inline-block;width: 22px;height: 22px;border-radius: 50%;background: #f44f56;line-height: 22px;text-align: center;vertical-align: 1px;color:#fff;
        }
      }
    }
  }
  .reason {
    width: 360px;height: 130px;background: #f9fafe;margin:0 auto;padding:0 30px;
    .p {
      font-size: 18px;font-weight: bold;padding-top:20px;color:#232325;
      span {
        color:#f44f56;font-size: 18px;
      }
    }
    .p1 {
      color:#b4b4b6;font-size: 14px;margin-top:5px;margin-bottom:10px;
      span {
        font-weight: bold;color:#232325;
      }
    }
    .p2 {
      color:#b4b4b6;font-size: 14px;
      button {
        background: none;color:#19489a;font-size: 14px;margin-right: 15px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .menus {
    display: flex;justify-content: center;margin-top:50px;
    button {
      width: 180px;height: 50px;border:1px solid #bfbfbf;background: #fff;font-size: 20px;border-radius: 5px;color:#404040;
      &:nth-child(1) {
        margin-right: 20px;background: #f44f56;border-color:#f44f56;color:#fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
